.App {
  text-align: center;
  max-width: 600px;
  margin: auto;
}

@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: left;
  margin: 12px;
}

.loading-dot {
  animation: dot-keyframes 1.5s infinite ease-in-out;
  background-color: #000;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin: 0px 2px;

  &:nth-child(2) {
    animation-delay: .5s;
  }

  &:nth-child(3) {
    animation-delay: 1s;
  }
}
